<template>
    <div class="flex items-center">
        123
    </div>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            required: true
        },
        getInvoicePayment: {
            type: Function,
            required: true
        }
    }
}
</script>